@font-face {
  font-family: 'Poppins';
  font-weight: normal;
  src: url('./assets/font/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'Poppins';
  font-weight: bold;
  src: url('./assets/font/Poppins-Bold.ttf');
}

@font-face {
  font-family: 'Poppins-Semibold';
  font-weight: normal;
  src: url('./assets/font/Poppins-SemiBold.ttf');
}

@font-face {
  font-family: 'Poppins-ExtraBold';
  font-weight: normal;
  src: url('./assets/font/Poppins-ExtraBold.ttf');
}

@font-face {
  font-family: 'Poppins';
  font-weight: lighter;
  src: url('./assets/font/Poppins-Light.ttf');
}
@font-face {
  font-family: 'Poppins-Thin';
  font-weight: normal;
  src: url('./assets/font/Poppins-Thin.ttf');
}

@font-face {
  font-family: 'Poppins-Medium';
  font-weight: 600;
  src: url('./assets/font/Poppins-Medium.ttf');
}

@font-face {
  font-family: 'OpenSans';
  font-weight: bold;
  src: url('./assets/font/OpenSans-Bold.ttf');
}
@font-face {
  font-family: 'OpenSans';
  font-weight: normal;
  src: url('./assets/font/OpenSans-Regular.ttf');
}


body {
  margin: 0;
  font-family: 'OpenSans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.css-ka6i2q-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #000000 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: inherit;
  color: inherit;
  transition: opacity 0.2s;
}
a.aDefault {
  text-decoration: underline !important;
  color: blue;
  transition: opacity 0.2s;
}
a:hover {
  opacity: 0.5;
}

li::marker {
  color: #F9CB48;
}

/* .MuiPaper-root {
  box-shadow: inherit !important;
  border: 1px solid black;
} */

.MuiPaper-root.Mui-expanded {
  border: none;
}

.MuiPaper-root.Mui-expanded>div:first-child {
  background-color: #F9CB48;
  border-radius: 10px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.MuiAccordionSummary-expandIconWrapper {
  >svg {
    width: 40px;
    height: 40px;
  }

  >svg>path {
    fill: #F9CB48 !important;
  }
}

.MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  >svg {
    width: 40px;
    height: 40px;
  }

  >svg>path {
    fill: black !important;
  }
}

.MuiInputBase-root.MuiOutlinedInput-root {
  input::placeholder {
    color: black !important;
    opacity: 1;
  }
}

.select-style {
  > fieldset {
    border: none;
  }
  .MuiInputLabel-root {
    color: black !important;
  }
  .MuiInputLabel-root.Mui-focused {
    color: black !important;
  }
}

.select-style-gray {
  > div {
   background-color: #F1F1F1;
 }
 > fieldset {
   border: none;
 }
 .MuiInputLabel-root {
   color: black !important;
}
 .MuiInputLabel-root.Mui-focused {
   color: black !important;
}
} 

.MuiListItem-root span {
  cursor:pointer;
  transition: opacity 0.2s;
}
.MuiListItem-root span:hover {
  opacity: 0.7;
}
.MuiListItem-root span:active {
  opacity: 0.3;
}

.Mui-focused .MuiOutlinedInput-notchedOutline  {
  border: 2px solid transparent !important;
  box-shadow: 0 0 5px #FF0082;
}

.Mui-checked{
  color: #FF0082 !important;
}

.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
  color: #370E74;
  z-index: 1;
}

.MuiInputBase-input.MuiOutlinedInput-input {
  color: #370E74;

}

.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected {
  background-color: #ff058212;
}